export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          {/* <p className="section--title">About</p> */}
          <h1 className="skills-section--heading">About Us</h1>
          <p className="hero--section-description">
          Apex innovations Pvt ltd is a Web & Mobile Software solutions provider 
          specialized in developing responsive websites, Mobile Apps & Web Apps. 
          The team specializes in application testing services., based in Colombo, 
          Sri Lanka. We are great with communication and very quick with responses.
          </p>
        </div>
      </div>
    </section>
  );
}
