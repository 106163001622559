import React from "react";

export default function HeroSection() {

  const scrollToContact = () => {
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          {/* <p className="section--title">Hey, I'm John</p> */}
          <h1 className="hero--section--title" style={{ fontSize: "3.5rem", lineHeight: "1.2" }}>
            <span className="hero--section-title--color">We Empower your Business with our</span>{" "}
            <br />
            Innovative Technology
          </h1>
          <p className="hero--section-description" style={{ fontSize: "1rem", lineHeight: "1.5" }}>
            Apex innovations (Pvt) Ltd is a leading Software company in Sri Lanka and we are here to fulfill
            all your requirements regarding Digital Marketing/ Software marketing, Website design/ development, branding, all kinds of graphic designs, 
            Software development and mobile app development in a very accurate manner as you are expecting.
          </p>
        </div>
        <button className="btn btn-primary" onClick={scrollToContact}>Get In Touch</button>
      </div>
      <div className="hero--section--img">
        <img src="./img/coverimage.png" alt="Hero Section" />
      </div>
    </section>
  );
}
